@import './styles/mat-theme.scss';

/* You can add global styles to this file, and also import other style files */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@mixin font($font-family, $font-file) {
	@font-face {
		font-family: $font-family;
    src: local('☞'+$font-file), local('$font-file'),
        url($font-file + '.woff2') format('woff2'),
        url($font-file + '.woff') format('woff');
		font-weight: normal;
		font-style: normal;
    font-display: swap;
	}
}

@include font('Gilroy-Regular', './assets/fonts/Gilroy-Regular/Gilroy-Regular');
@include font('Gilroy-Bold', './assets/fonts/Gilroy-Bold/Gilroy-Bold');
@include font('Gilroy-SemiBold', './assets/fonts/Gilroy-SemiBold/Gilroy-SemiBold');
@include font('Gilroy-Light', './assets/fonts/Gilroy-Light/Gilroy-Light');

@import "intl-tel-input/build/css/intlTelInput.css";

@import './styles/styles-rtl.scss';
@import './styles/base.scss';

// :host{

// ::-webkit-scrollbar-track {
//   @apply bg-lightblue;

// }

// ::-webkit-scrollbar-thumb {
//   background: #babac0;
//   border-radius: 16px;
// }

// ::-webkit-scrollbar-thumb:hover {
//   background: #555;
// }


// }

body,
html {
	margin: 0;
	padding: 0;
	height: 100%;
}

body {
  @apply font-body;
}


label{
  @apply text-base;
}

input[type=text]  {
  width: 332px;
  // padding-left: 1rem !important;
  // padding-right: 1rem !important;
}


html[lang="ar"] body, html[lang="ar"] h1, button {
  font-family: Tajawal, sans-serif;
}

h1 {
  @apply text-3xl font-bold font-headline;
}

h2 {
  @apply text-2xl font-title;
}

button {
  @apply font-title text-lg;
}

.iti {
  @apply w-full;
}

table {
  width: 100%;
}

.mat-table {
  thead {
    @apply bg-lightblue;
    tr {
      @apply h-10
    }
  }

  .mat-cell {
    @apply text-gray-4;
  }
  tbody tr {
    @apply h-16 font-title text-sm;
  }
}

.mat-select-value {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  @apply font-body text-gray-6
}

.mat-paginator-container {
  .mat-select-value {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mat-paginator-navigation-previous {
    margin-right: 0.5rem;
  }
}

.mat-paginator-sticky {
  bottom: 0;
  position: sticky;
  z-index: 10;
}

.mat-tab-group {
  @apply font-body;
}


.mat-option{
  @apply  text-gray-6 font-body
}

.mat-dialog-container {
  border-radius: 24px !important;
}

button{
  @apply appearance-none;
}


.mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
  height: 8px;
  border-radius: 10px;
}
.mat-slider.mat-slider-horizontal .mat-slider-track-background,
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  height: 100%;
}
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  background-color: red;
}

.mat-slider-thumb-label {
  transform: rotate(45deg) !important;
  top: -10px !important;
  background-color: white !important;
  border: 3px solid red;
  border-radius: 50% !important;
  width: 25px !important;
  height: 25px !important;
}

.mat-slider-thumb-label-text {
  opacity: 1 !important;
}

.mat-slider-thumb-container {
  height: 60px;
  width: 60px;
}
