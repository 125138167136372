// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import './custom-palletes.scss';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$heading-font-family: "'Gilroy Bold', sans-serif";
$body-font-family: "'Gilroy Regular', sans-serif";

$dx-portal-primary: mat.define-palette($md-primary);
$dx-portal-accent: mat.define-palette($md-accent);

// $dx-portal-primary: mat.define-palette(mat.$blue-palette);
// $dx-portal-accent: mat.define-palette(mat.$deep-purple-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$dx-portal-warn: mat.define-palette(mat.$red-palette);

// $dx-portal-low: mat.define-palette($md-low);

$typography: mat.define-typography-config(
  // Specify "Comic Sans" as the default font family for all levels.
  $headline: mat.define-typography-level(2rem, $font-family: $heading-font-family),
  $body-1: mat.define-typography-level(1rem, $font-family: $body-font-family)
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$dx-portal-theme: mat.define-light-theme((
  color: (
    primary: $dx-portal-primary,
    accent: $dx-portal-accent,
    warn: $dx-portal-warn,
   
  ),
  typography: $typography
));

/* For use in src/lib/core/theming/_palette.scss */
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($dx-portal-theme);
// @include mat-select-theme($dx-portal-theme);