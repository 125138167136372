/**********************
1. General
2. Typography
3. Forms
***********************/


/* 1. General
=======================================================================*/

body,
html {
	margin: 0;
	padding: 0;
	height: 100%;
}

body {
  @apply font-body;
}

/* 2. Typography
========================================================================= */

h1 {
  @apply text-3xl font-bold font-headline;
}

h2 {
  @apply text-2xl font-title;
}

a {
  @apply text-primary;
}

/* 3. Forms
=========================================================================*/


button {
  @apply font-title text-lg;
  // -webkit-appearance: initial;
}

input:not([type="image"]), textarea {
  @apply pl-8 py-2.5 rounded-xl border
}

input:not([type="image"]):focus, textarea:focus {
  @apply outline-none border-gradient-1;
}

input, textarea {
  direction: ltr;
}

[hidden] {
  visibility: hidden;
}

input[disabled] {
  @apply text-gray-3;
}

button[disabled] {
  color: #BDBDBD;
  border-color: #BDBDBD;
  pointer-events: none;
  background-color: #F2F2F2;
}